// Custom variables + Vendor customization

// Bootstrap
@import "../node_modules/bootstrap/scss/functions";

// Import UNICEF variable overrides
@import "variables";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Remove some colors from theme to make the theme more lean
$theme-colors: map-remove($theme-colors, "secondary", "info", "light", "dark");


// Optional Bootstrap
// This list comes from https://github.com/twbs/bootstrap/blob/master/scss/bootstrap.scss

@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/input-group";
@import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/jumbotron";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/media";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
//@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/print";


// UNICEF's OVERRIDES + Custom SCCSS class definitions
// We follow the same structure that above for overrides.
// https://github.com/twbs/bootstrap/tree/v4-dev/scss

//@import "type";
@import "alert";
@import "button";
@import "card";
//@import "lists";
@import "forms";
//@import "boxes";
//@import "utils";
//@import "display-data";
//@import "popups";
@import "progress";
@import "tables";
//@import "tab";
@import "type";
//@import "notifications";
//@import "spinners";
@import "navbar";
@import "dropdown";
@import "nav";
