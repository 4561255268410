//dropdown

.dropdown-menu {
  padding-top: $dropdown-padding-y;
  margin-top: 0rem;
  border: $dropdown-border;
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  box-shadow: $dropdown-box-shadow;

  .dropdown-item {
    line-height: $dropdown-line-height;
    padding: 0.5rem 1rem;
    text-decoration-line: none;
    -webkit-text-decoration-line: none;

    &:hover {
      color: $white;
      border: none;
      background-color: $unicef-dark-blue;
      text-decoration-line: none;
      -webkit-text-decoration-line: none;
    }
  }
}

/*
@include media-breakpoint-up(sm) {
  .dropdown-menu {
    box-shadow: none;
    border: none;
  }
}
*/