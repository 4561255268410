.btn {
  font-size: $btn-font-size;
  text-transform: $btn-transform;
  min-width: $btn-min-width;
  text-decoration-line: none;
  -webkit-text-decoration-line: none;

  &.btn-link {
    &:focus {
      margin-top: auto;
      border: $link-focus-border;
      display: inline-block;
      text-decoration: underline;
    }
  }
}
