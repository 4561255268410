.progress {
  height: $progress-height;
  border-radius: $progress-border-radius;
}

.progress-min-max {
  color: $unicef-lighter;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-bottom: 0.75rem;
}
