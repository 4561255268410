//Nav bar
nav {
  background-color: $unicef-blue;
  a {
    text-decoration-line: none;
    color: $white;
    -webkit-text-decoration-line: none;
    &:hover {
      color: $white;
    }
  }
  .nav-link {
    padding-left: 0;
    margin-right: 0;
    .badge {
      background-color: $body-bg;
      padding: 4px 6px;
      color: $unicef-dark-blue;
      @include border-radius(4rem);
      margin-right: 0.5rem;
    }

    .fa {
      background-color: $body-bg;
      padding: 4px 6px;
      color: $unicef-dark-blue;
      @include border-radius(3rem);
      margin-right: 0.5rem;
    }
  }
}

// Tablets and Desktop view

@media only screen and (min-width: 767.98px) {
  .navbar {
    padding: 0 0 0 0.5rem;
  }
  .navbar-nav {
    background-color: rgba(55, 78, 162, 0.6);
    color: $white;
    padding: 0.25rem 0.25rem 0.25rem 1rem;
    &.rounded-left {
      border-top-left-radius: 2rem !important;
      border-bottom-left-radius: 2rem !important;
    }
    a:hover {
      text-decoration-line: underline;
    }
  }
}

// Desktop Only
@media only screen and (min-width: 992px) {
  .navbar-line {
    border-right: 1px solid $white;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

// Mobile View Only
@media only screen and (max-width: 767.98px) {
  .nav {
    display: block;
  }
  .navbar-brand {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .navbar-nav {
    background-color: $white;
    display: block;
    .nav-item {
      border-bottom: 1px solid $unicef-borders;
    }
    .nav-link {
      display: block;
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      border: 1px solid transparent;
      .badge {
        color: $white;
        background-color: $black;
      }

      .fa {
        color: $white;
        background-color: $black;
      }
    }
    a {
      color: $black;
      text-decoration-line: none;
      -webkit-text-decoration-line: none;
      &:hover {
        color: $black;
        background-color: $nav-tabs-link-active-bg;
        text-decoration-line: none;
        -webkit-text-decoration-line: none;
      }
    }
  }
}

.disabled {
  cursor: auto;
}
//Skip Navigation
.skip {
  display: block;
  margin-top: -40px;
  padding: 0.5rem 1rem;

  &:focus {
    margin-top: auto;
    margin-bottom: 5px;
    border: $link-focus-border;
    display: inline-block;
    text-decoration: underline;
  }

  &:active {
    display: inline;
  }
}
