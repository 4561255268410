.table {
  border: $table-border;
  thead {
    th {
      border-bottom: $table-border;
    }
  }

  th {
    color: $table-head-color;
    font-size: $table-head-font-size;
    line-height: $table-head-line-height;
    border: none;
    padding: 1rem 1rem;
  }

  td {
    font-size: $table-cell-font-size;
    vertical-align: middle;
    &:last-child {
      text-align: right;
    }
  }

  a {
    text-decoration: $link-in-table-decoration;
    &:hover {
      text-decoration-line: underline;
      -webkit-text-decoration-line: underline;
    }
  }
}
